import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <p>
            Prado GXL 2012
          </p>

          <h2>Head Unit Connectors</h2>
          <p><img src="gfx/head-unit-h27.png" className="head-unit-connector" alt="h27" /></p>
          <p><img src="gfx/head-unit-h28.png" className="head-unit-connector" alt="h28" /></p>
          <p><img src="gfx/head-unit-h29.png" className="head-unit-connector" alt="h29" /></p>

          <h2>Rear View Monitor Connectors</h2>
          <p><img src="gfx/rear-view-h32.png" className="head-unit-connector" alt="h32" /></p>
        </header>


      </div>
    );
  }
}

export default App;
